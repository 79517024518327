.v-wrapper {
  color: white;
  position: relative;
  padding-bottom: 2rem;
}

.v-container {
  justify-content: space-around;
}
.v-container .image-container {
  border: 8px solid rgb(232 232 232 /93%);
}
.v-left {
  width: 100%;
}

.v-container > div {
  flex: 1;
}
.v-right {
  gap: 0.5rem;
}
.accordion {
  margin-top: 2rem;
  border: none;
}
.accordionItem {
  background: white;
  border: 1px solid rgb(232 232 232 /93%);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
.accordionItem.expanded {
  box-shadow: var(--shadow);
  border-radius: 6px;
}
.accordionButton.flexCenter {
  background: white;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  padding: 10px;
  background: #eeeeff;
  border-radius: 5px;
}
.icon svg {
  fill: var(--blue);
}

.accordionButton .primaryText {
  font-size: 1.1rem;
}

@media (max-width: 1024px) {
  .v-container {
    flex-direction: column;
  }
  .v-left {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    .accordion {
      font-size: 0.8rem;
    }
  }
}
